<template>
  <div>
    <h3>{{ title }}: {{ today }}</h3>
    <div class="h5 text-muted">
      {{ description }}
    </div>
    <hr />
    <div class="accordion" role="tablist">
      <div class="card">
        <div class="card-header" v-b-toggle.searchParams>
          <i
            class="fas fa-chevron-up when-open card-title float-right mt-1 mb-0"
          ></i>
          <i
            class="fas fa-chevron-down when-closed card-title float-right mt-1 mb-0"
          ></i>
          <div class="card-title mb-0">Search</div>
        </div>
        <b-collapse
          id="searchParams"
          accordion="admin-check-in-out-search"
          role="tabpanel"
        >
          <div class="card-body">
            <ValidationObserver ref="resSearchForm">
              <FormErrorAlert
                v-if="errors.length > 0"
                :errors="errors"
                :formRef="this.$refs.resSearchForm"
              />
              <form @submit.prevent="onSubmit">
                <div class="row mb-2">
                  <div class="col-md-4 col-sm-12">
                    <TextInput
                      name="Reservation Number"
                      id="reservationNumber"
                      v-model="checkSearch.reservationNumber"
                      rules="min:3|alphaNumSpaces|max:40"
                      class="mb-2"
                    />
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <TextInput
                      name="Customer Last Name"
                      id="customerLastName"
                      v-model="checkSearch.customerLastName"
                      rules="min:2|customerName|max:30"
                      class="mb-2"
                    />
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <TextInput
                      name="Primary Occupant Last Name"
                      id="primaryOccupantLastName"
                      v-model="checkSearch.primaryOccupantLastName"
                      rules="min:2|customerName|max:30"
                      class="mb-2"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <button
                      type="submit"
                      class="btn btn-primary mr-2 my-1"
                      :disabled="parentLoadingSearch"
                    >
                      <span
                        v-if="parentLoadingSearch"
                        class="spinner-border spinner-border-sm mx-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-else>SEARCH</span>
                    </button>
                    <button
                      type="button"
                      class="btn btn-secondary mr-2 my-1"
                      @click="resetAllFields"
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Reservations</div>
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary mr-2 my-1"
              @click="selectAllReservations"
            >
              SELECT ALL
            </button>
            <button
              type="button"
              class="btn-primary btn my-1"
              @click="deselectAllReservations"
            >
              DESELECT ALL
            </button>
          </div>
        </div>
        <hr />
        <div class="row mb-2">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <p class="mb-2">
                  {{ reservations.filter(x => x.isSelected).length }} of
                  {{ reservations.length }} total reservations selected
                </p>
              </div>
              <div
                class="col-12"
                style="max-height: 300px; overflow-y: hidden;"
              >
                <b-table
                  ref="table"
                  id="reservationTable"
                  striped
                  :fields="fields"
                  :items="reservations"
                  small
                  outlined
                  empty-text="No Reservation Results..."
                  empty-filtered-text="No Reservation Results..."
                  sticky-header="300px"
                  no-border-collapse
                  selectable
                  show-empty
                  :busy="parentLoadingSearch"
                  primary-key="id"
                  @sort-changed="onSort"
                  no-select-on-click
                  :sort-null-last="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-compare="sortCompare"
                  bordered
                  sort-icon-left
                >
                  <template v-slot:table-busy>
                    <div class="text-center my-2">
                      <span
                        class="spinner-border spinner-border-sm mx-auto"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </template>
                  <template v-slot:cell(isSelected)="data">
                    <template v-if="data.rowSelected">
                      <i
                        @click="deselectReservation(data.item.id)"
                        class="align-middle fa-fw fa-check-circle fas text-primary ml-2"
                      ></i>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <i
                        @click="selectReservation(data.item.id)"
                        class="align-middle fa-fw fa-check-circle far ml-2"
                      ></i>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                  <template v-slot:cell(id)="data">
                    <button
                      class="btn btn-primary btn-sm m-1"
                      @click="viewReservation(data.item.id)"
                    >
                      View
                    </button>
                    <button
                      class="btn btn-primary btn-sm m-1"
                      @click="modifyReservation(data.item.id)"
                    >
                      {{ individualAction }}
                    </button>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary mr-2 my-1"
              @click="onBulkAction"
            >
              {{ bulkAction }}
            </button>
            <button type="button" class="btn btn-cancel my-1" @click="goHome">
              BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import moment from "moment";

export default {
  name: "AdminBulkCheckInOutForm",
  props: {
    title: String,
    description: String,
    individualAction: String,
    bulkAction: String,
    path: String,
    parentLoadingSearch: Boolean,
    parentReservations: Array,
    sortBy: String
  },
  components: {
    ValidationObserver,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      errors: [],
      reservations: [],
      sortDesc: true,
      fields: [
        { key: "isSelected", label: "Selected", sortable: true },
        { key: "reservationNumber", sortable: true },
        { key: "customerName", label: "Customer", sortable: true },
        { key: "phone", sortable: true },
        { key: "emailAddress", sortable: true },
        { key: "numberOfPeople", sortable: true },
        {
          key: "primaryOccupantName",
          label: "Primary Occupant",
          sortable: true
        },
        { key: "park", sortable: true },
        { key: "spot", sortable: true },
        { key: "arrivalDate", label: "Arrival", sortable: true },
        { key: "departureDate", label: "Departure", sortable: true },
        { key: "id", label: "Actions", sortable: false }
      ]
    };
  },
  computed: {
    selectedReservations() {
      return this.reservations
        .filter(x => x.isSelected === true)
        .map(y => y.id);
    },
    today() {
      return moment().format("dddd, MMMM D, YYYY");
    },
    checkSearch: {
      get() {
        return this.$store.getters["admin/checkSearchParams"];
      },
      set(val) {
        this.$store.commit("admin/setCheckSearchParams", val);
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.resSearchForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.resSearchForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.resSearchForm.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          this.checkSearch.reservationNumber = this.checkSearch
            .reservationNumber
            ? this.checkSearch.reservationNumber.replace(/\s/g, "")
            : null;
          this.$emit("search", this.checkSearch);
        }
      });
    },
    resetAllFields() {
      this.checkSearch = {};
      this.$emit("search", {});
    },
    goHome() {
      this.checkSearch = {};
      this.$router.push("/admin").catch(() => {});
    },
    viewReservation(id) {
      this.$router.push(`/admin/reservation-details/${id}`).catch(() => {});
    },
    modifyReservation(id) {
      this.$router.push(`/admin/${this.path}/${id}`).catch(() => {});
    },
    onBulkAction() {
      if (this.selectedReservations.length !== 0) {
        this.$emit("bulkAction", this.selectedReservations);
      } else {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "You must select at least one reservation...",
          layer: "admin"
        });
      }
    },
    selectReservation(id) {
      this.reservations = this.reservations.map(x => {
        return { ...x, isSelected: x.id === id ? true : x.isSelected };
      });
      this.onSort();
    },
    deselectReservation(id) {
      this.reservations = this.reservations.map(x => {
        return { ...x, isSelected: x.id === id ? false : x.isSelected };
      });
      this.onSort();
    },
    onSort() {
      // same here for set timeout, have to give the table a sec to return the sorted items
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.reservations
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 100);
    },
    async selectAllReservations() {
      this.reservations = this.reservations.map(x => {
        return {
          ...x,
          isSelected: true
        };
      });
      this.onSort();
    },
    deselectAllReservations() {
      this.reservations = this.reservations.map(x => {
        return {
          ...x,
          isSelected: false
        };
      });
      this.onSort();
    },
    sortCompare(aRow, bRow, key) {
      const a = aRow[key];
      const b = bRow[key];
      if (key === "arrivalDate" || key === "departureDate") {
        return moment(a, "MM/DD/YYYY").isBefore(moment(b, "MM/DD/YYYY"))
          ? -1
          : moment(a, "MM/DD/YYYY").isAfter(moment(b, "MM/DD/YYYY"))
          ? 1
          : 0;
      } else {
        return a.localeCompare(b);
      }
    }
  },
  watch: {
    parentReservations() {
      this.reservations = JSON.parse(JSON.stringify(this.parentReservations));
    }
  }
};
</script>

<style scoped>
.grow {
  flex-grow: 1;
}
.card-header {
  cursor: pointer;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
